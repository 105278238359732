'use strict';

//#region Шапка
// Пункты меню
var menuArrows = document.querySelectorAll('.menu__arrow');

if (menuArrows.length > 0) {
	var _loop = function _loop(i) {
		var menuArrow = menuArrows[i];
		menuArrow.addEventListener('click', function (e) {
			menuArrow.parentElement.classList.toggle('_active');
		});
	};

	for (var i = 0; i < menuArrows.length; i++) {
		_loop(i);
	}
}

// Анимация бургера
var burger = document.querySelector('.header__burger');
var menu = document.querySelector('.menu__body');

burger.addEventListener('click', function () {
	burger.classList.toggle('_active');
	menu.classList.toggle('_active');
	document.body.classList.toggle('_lock');
});

// Плавающая шапка
var lastScroll = 0;
var defaultOffset = 100;
var header = document.querySelector('.header');
var scrollTopButton = document.querySelector('.scroll-top__button');

var scrollPosition = function scrollPosition() {
	return window.pageYOffset || document.documentElement.scrollTop;
};
var containHide = function containHide() {
	return header.classList.contains('_hide');
};
window.addEventListener('scroll', function () {
	if (scrollPosition() > lastScroll && !containHide() && scrollPosition() > defaultOffset) {
		header.classList.add('_hide');
	} else if (scrollPosition() < lastScroll && containHide()) {
		header.classList.remove('_hide');
	}
	if (lastScroll > 100) {
		header.classList.add('_paint');
	} else {
		header.classList.remove('_paint');
	}

	// Явление кнопки "Скролл вверх" народу
	if (lastScroll > 600) {
		scrollTopButton.classList.add('_show');
	} else {
		scrollTopButton.classList.remove('_show');
	}

	lastScroll = scrollPosition();
});
//#endregion

//#region Валидация формы в модальном окне
var forms = document.querySelectorAll('.validation-form');

if (forms) {
	var _loop2 = function _loop2(i) {
		var form = forms[i];
		form.addEventListener('submit', function (e) {
			e.preventDefault();
			var name = form.querySelector('.validation-name');
			var phone = form.querySelector('.validation-phone');
			var checkbox = form.querySelector('.validation-checkbox');
			var formAlert = form.querySelector('.validation-alert');
			var formLabel = form.querySelector('.validation-label');
			var allOk = true;
			if (name.value == '' || name.value == null) {
				allOk = false;
				e.preventDefault();
				formAlert.classList.add('_error');
				name.classList.add('_error');
			} else {
				name.classList.remove('_error');
			}
			if (phone.value == '' || phone.value == null) {
				allOk = false;
				e.preventDefault();
				formAlert.classList.add('_error');
				phone.classList.add('_error');
			} else {
				phone.classList.remove('_error');
			}
			if (!checkbox.checked) {
				allOk = false;
				e.preventDefault();
				formAlert.classList.add('_error');
				formLabel.classList.add('_error');
			} else {
				formLabel.classList.remove('_error');
			}
			console.log($(undefined));
			if (allOk === true) {
				var m_method = $(undefined).attr('method');
				var m_action = $(undefined).attr('action');
				var m_data = $(undefined).serialize();
				$('section.popup#popup-spinner').addClass('_open');
				$.ajax({
					type: m_method,
					url: m_action,
					data: m_data,
					resetForm: 'true',
					success: function success(result) {
						$('section.popup#popup-spinner').removeClass('_open');
						$('section.popup#popup-thx').addClass('_open');
						setTimeout(function () {
							$(form)[0].reset();
							$('section.popup#popup-thx').removeClass('_open');
							$('#popup-request').removeClass('_open');
						}, 2000);
					}
				});
			}
		});
	};

	for (var i = 0; i < forms.length; i++) {
		_loop2(i);
	}
}

//#endregion

//#region Кнопка скролла вверх
document.addEventListener('DOMContentLoaded', function () {
	document.getElementById('scroll-top-btn').onclick = function () {
		scrollTo(0, 500);
	};
});

function scrollTo(to) {
	var duration = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;

	var element = document.scrollingElement || document.documentElement,
	    start = element.scrollTop,
	    change = to - start,
	    startDate = +new Date(),

	// t = current time
	// b = start value
	// c = change in value
	// d = duration
	easeInOutQuad = function easeInOutQuad(t, b, c, d) {
		t /= d / 2;
		if (t < 1) return c / 2 * t * t + b;
		t--;
		return -c / 2 * (t * (t - 2) - 1) + b;
	},
	    animateScroll = function animateScroll() {
		var currentDate = +new Date();
		var currentTime = currentDate - startDate;
		element.scrollTop = parseInt(easeInOutQuad(currentTime, start, change, duration));
		if (currentTime < duration) {
			requestAnimationFrame(animateScroll);
		} else {
			element.scrollTop = to;
		}
	};
	animateScroll();
}
//#endregion

//#region Параллакс на главном экране
"use strict";
window.onload = function () {
	var parallax = document.querySelector('.mainscreen');
	var short = document.querySelector('.short');

	if (document.body.clientWidth > 992) {
		if (parallax) {
			var setMouseParallaxStyle = function setMouseParallaxStyle() {
				var distX = coordXPercent - positionX;
				var distY = coordYPercent - positionY;

				positionX += distX * speed;
				positionY += distY * speed;

				letter.style.cssText = 'transform: translate(' + positionX / ratio + '%,' + positionY / ratio + '%);';

				requestAnimationFrame(setMouseParallaxStyle);
			};

			var letter = document.querySelector('.mainscreen__decor');
			var ratio = 50;
			var speed = 0.02;

			var positionX = 0;
			var positionY = 0;
			var coordXPercent = 0;
			var coordYPercent = 0;

			setMouseParallaxStyle();

			parallax.addEventListener('mousemove', function (e) {
				var parallaxWidth = letter.offsetWidth;
				var parallaxHeight = letter.offsetHeight;

				var coordX = e.pageX - parallaxWidth / 2;
				var coordY = e.pageY - parallaxHeight / 2;

				coordXPercent = coordX / parallaxWidth * 100;
				coordYPercent = coordY / parallaxHeight * 100;
			});
		}

		if (short) {
			var setParallaxItemsStyle = function setParallaxItemsStyle(scrollTopPercent) {
				watermark.style.cssText = 'transform: translate(0%,-' + scrollTopPercent / 3 + '%);';
			};

			var watermark = document.querySelector('.short__watermark');
			var thresholdSets = [];

			for (var i = 0; i <= 1.0; i += 0.01) {
				thresholdSets.push(i);
			}

			var callback = function callback(entries, observer) {
				var scrollTopPercent = window.pageYOffset / short.offsetHeight * 100;
				setParallaxItemsStyle(scrollTopPercent);
			};

			var observer = new IntersectionObserver(callback, {
				threshold: thresholdSets
			});

			observer.observe(short);
		}
	}
};
//#endregion

//#region Вкладки на странице объектов
/* document.querySelectorAll('.tabs__trigger').forEach((trigger) => {
trigger.addEventListener('click', e => {
e.preventDefault();
const id = e.target.getAttribute('href').replace('#', '');
let tabTitle = e.target.innerText;
let pageTitle = document.querySelector('.bar__subtitle');
pageTitle.innerText = tabTitle;

document.querySelectorAll('.tabs__trigger').forEach(
(child) => child.classList.remove('tabs__trigger--active')
);
document.querySelectorAll('.tabs__item').forEach(
(child) => child.classList.remove('tabs__item--active')
);
trigger.classList.add('tabs__trigger--active');
document.getElementById(id).classList.add('tabs__item--active');
});
});
if (document.querySelector('.tabs__trigger')) {
document.querySelector('.tabs__trigger').click();
} */
//#endregion

//#region Кнопки площади в фильтре на странице объектов
var filterBtns = document.querySelectorAll('.filter__check-btn');
if (filterBtns) {
	var _loop3 = function _loop3(i) {
		var filterBtn = filterBtns[i];
		filterBtn.addEventListener('click', function () {
			filterBtn.classList.toggle('filter__check-btn--active');
		});
	};

	for (var i = 0; i < filterBtns.length; i++) {
		_loop3(i);
	}
}
//#endregion

//#region Слайдер на странице объекта
if (document.querySelector('.slider')) {
	new Swiper('.slider', {
		navigation: {
			nextEl: '.swiper-button-next',
			prevEl: '.swiper-button-prev'
		},
		autoHeight: true,
		slideToClickedSlide: true,
		watchOverflow: true,
		observer: true,
		observeParents: true,
		observeSlideChildren: true,
		preloadImages: false,
		lazy: {
			loadOnTransitionStart: false,
			loadPrevNext: false
		},
		watchSlidesProgress: true,
		watchSlidesVisibility: true,
		thumbs: {
			swiper: {
				el: '.slider-small',
				watchOverflow: true,
				observer: true,
				observeParents: true,
				observeSlideChildren: true,
				spaceBetween: 15,
				slideToClickedSlide: true,
				preloadImages: false,
				lazy: {
					loadOnTransitionStart: false,
					loadPrevNext: false
				},
				watchSlidesProgress: true,
				watchSlidesVisibility: true,
				breakpoints: {
					320: {
						slidesPerView: 3
					},
					501: {
						slidesPerView: 5
					}
				}
			}
		}
	});
}
//#endregion

//#region Яндекс.Карты
var rent = document.querySelector('.rent');
if (rent) {
	var init = function init() {
		// Создание карт
		var map = new ymaps.Map('map-objects', {
			center: center,
			zoom: 16
		});

		// let map1 = new ymaps.Map('map-commercial', {
		// 	center: center,
		// 	zoom: 16,
		// });

		//Создание массивов меток
		var placemarks = [];
		var placemarks1 = [];

		//Удаление элементов управления с карты
		map.controls.remove('searchControl');
		map.controls.remove('trafficControl');
		map.controls.remove('geolocationControl');
		map.controls.remove('typeSelector');
		map.controls.remove('fullscreenControl');
		map.controls.remove('rulerControl');
		map.controls.remove(['scrollZoom']);
		map.behaviors.disable('scrollZoom');
		// map1.controls.remove('searchControl');
		// map1.controls.remove('trafficControl');
		// map1.controls.remove('geolocationControl');
		// map1.controls.remove('typeSelector');
		// map1.controls.remove('fullscreenControl');
		// map1.controls.remove('rulerControl');
		// map1.controls.remove(['scrollZoom']);
		// map1.behaviors.disable('scrollZoom');

		// Определяем элементы каждой из карт на двух вкладках
		var mapObjects = document.getElementById('map-objects');
		var mapPanel = document.querySelector('.map__panel');
		var mapLinks = mapObjects.querySelectorAll('.map__link');
		//const mapLinksCommercial = mapCommercial.querySelectorAll('.map__link');


		for (var i = 0; i < mapLinks.length; i++) {
			var mapLink = mapLinks[i];

			// Берем текст из HTML-списка
			var title = mapLink.querySelector('.map__heading').innerText;
			var address = mapLink.querySelector('.map__address').innerText;
			var link = mapLink.getAttribute('href');

			// Вытаскиваем ширину и долготу
			var mLatitude = Number(mapLink.dataset.cart.split(',')[0]);
			var mLongitude = Number(mapLink.dataset.cart.split(',')[1]);
			//console.log("~" + mLatitude + "~" + mLongitude + "~");

			// Создаем точку
			placemarks[i] = new ymaps.Placemark([mLatitude, mLongitude], {
				balloonContentHeader: '\n<a href="' + link + '" class="balloon-title">' + address + '</a>\n',
				balloonContentFooter: '' + title
			}, {
				iconLayout: 'default#image',
				iconImageHref: '/template/images/placemark.svg',
				iconImageSize: [150, 150],
				iconImageOffset: [-70, -85]
			});

			// Добавляем точку на карту
			map.geoObjects.add(placemarks[i]);
		}

		mapPanel.addEventListener('mouseover', function (e) {
			var target = e.target;
			//console.log(target);


			if (target.closest('.map__link')) {
				var _title = target.closest('.map__link').querySelector('.map__heading').innerText;
				var _address = target.closest('.map__link').querySelector('.map__address').innerText;
				var _link = target.closest('.map__link').getAttribute('href');
				var xPrev = Number(target.dataset.cart.split(',')[0]);
				var yPrev = Number(target.dataset.cart.split(',')[1]);
				// let xPrev = Number(target.dataset.latitude);
				// let yPrev = Number(target.dataset.longitude);
				map.panTo([xPrev, yPrev], {
					duration: 500,
					timingFunction: 'linear',
					flying: false,
					safe: false
				});
				setTimeout(function () {
					map.panTo([xPrev, yPrev], {
						duration: 500,
						timingFunction: 'linear',
						flying: false,
						safe: false
					});
				}, 0);
				map.balloon.open([Number(target.dataset.cart.split(',')[0]), Number(target.dataset.cart.split(',')[1])], {
					content: '\n<div class="balloon-wrapper" style="margin-bottom: 30px;">\n\t<div class="balloon-title-wrap">\n\t\t<a href="' + _link + '" class="balloon-title">' + _address + '</a>\n\t</div>\n\t<div class="balloon-footer">\n\t\t' + _title + '\n\t</div>\n</div>\n'
				});
				var par = $('.balloon-wrapper').parent().parent().parent().parent().parent();
				console.log(par);
				$(par).css({
					'top': '-150px;'
				});
			}
		});
	};

	// Ждем загрузки карт
	ymaps.ready(init);

	var center = [55.159608119492844, 61.40261080259067];
}

/* const objectMap = document.getElementById('object__map');
if (objectMap) {
const center = [+objectMap.dataset.latitude, +objectMap.dataset.longitude];
let link = objectMap.dataset.link;
let title = objectMap.dataset.title;
let subtitle = objectMap.dataset.subtitle;
ymaps.ready(init);


function init() {
let map = new ymaps.Map('object__map', {
center: center,
zoom: 16,
});

let placemark = new ymaps.Placemark(center, {
balloonContentHeader: `
<a href="${link}" class="balloon-title">${title}</a>
`,
balloonContentFooter: `${subtitle}`,
}, {
iconLayout: 'default#image',
iconImageHref: '/template/images/placemark.svg',
iconImageSize: [150, 150],
iconImageOffset: [-70, -85]
});

map.controls.remove('searchControl');
map.controls.remove('trafficControl');
map.controls.remove('geolocationControl');
map.controls.remove('typeSelector');
map.controls.remove('fullscreenControl');
map.controls.remove('rulerControl');
map.controls.remove(['scrollZoom']);
map.behaviors.disable('scrollZoom');

map.geoObjects.add(placemark);
}

} */
//#endregion

//#region Слайдеры
if (document.querySelector('.swiper')) {
	new Swiper('.swiper', {
		navigation: {
			nextEl: '.swiper__button-next',
			prevEl: '.swiper__button-prev'
		},
		keyboard: {
			enabled: true,
			onlyInViewport: true
		},
		slidesPerView: 3,
		watchOverflow: true,
		spaceBetween: 30,
		breakpoints: {
			320: {
				slidesPerView: 1,
				spaceBetween: 10
			},
			360: {
				slidesPerView: 1.5
			},
			480: {
				slidesPerView: 2
			},
			992: {
				slidesPerView: 2.5,
				spaceBetween: 20
			},
			1024: {
				slidesPerView: 3,
				spaceBetween: 30
			}
		}
	});
}
if (document.querySelector('.partners__slider')) {
	$(document).ready(function () {
		$('.partners__slider').slick({
			arrows: false,
			slidesToShow: 4,
			rows: 3,
			autoplay: true,
			pauseOnHover: false,
			responsive: [{
				breakpoint: 1280,
				settings: {
					slidesToShow: 3
				}
			}, {
				breakpoint: 993,
				settings: {
					slidesToShow: 2
				}
			}, {
				breakpoint: 600,
				settings: {
					slidesToShow: 1
				}
			}]
		});
	});
}
//#endregion

//#region Селект в фильтре для плагина
var filter = document.querySelector('.filter');

if (filter) {
	checkForSelects();
	window.addEventListener('resize', function () {
		checkForSelects();
	});
}

function checkForSelects() {
	if (window.innerWidth <= 992) {
		filter.querySelectorAll('.filter__buttons').forEach(function (el) {
			if (el.contains(el.querySelector('select'))) {
				var select = el.querySelector('select');
				el.closest('.filter__label').style = "margin-left: 5px; margin-right: 5px;";
				el.closest('.filter__label').querySelector('span').style = "margin-left: -5px;";
				el.style = "margin-top: 0px !important";
				select.classList.remove('filter__select');
				select.removeAttribute('multiple');
				select.removeAttribute('size');
				el.querySelector('option').classList.remove('filter__check-btn');
			}
		});
	}
	if (window.innerWidth >= 993) {
		filter.querySelectorAll('.filter__buttons').forEach(function (el) {
			if (el.contains(el.querySelector('select'))) {
				var select = el.querySelector('select');
				el.closest('.filter__label').querySelector('span').style = "margin-left: px;";
				el.style = "margin-top: -4.5px !important";
				select.setAttribute('multiple', 'true');
				select.setAttribute('size', '5');
				el.querySelector('option').classList.add('filter__check-btn');
			}
		});
	}
}
//#endregion

if (document.getElementById('mapOb')) {
	var _init = function _init() {
		var myMap = new ymaps.Map("mapOb", {
			center: [x, y],
			zoom: 16
		});
		// place = new ymaps.Placemark([x, y], {
		// 	balloonContentHeader: `<a href="111" class="balloon-title">111</a>`,
		// 	balloonContentFooter: `111`,
		// }, {
		// 	iconLayout: 'default#image',
		// 	iconImageHref: '/template/images/placemark.svg',
		// 	iconImageSize: [150, 150],
		// 	iconImageOffset: [-70, -85]
		// });
		// myMap.geoObjects.add(place);
		myMap.geoObjects.add(new ymaps.Placemark([x, y], {
			balloonContentHeader: '',
			balloonContentFooter: ''
		}, {
			iconLayout: 'default#image',
			iconImageHref: '/template/images/placemark.svg',
			iconImageSize: [150, 150],
			iconImageOffset: [-70, -85]
		}));
		console.log(ymaps);
	};

	//  place = new ymaps.Placemark([x, y], {
	// 	balloonContentHeader: `<a href="111" class="balloon-title">111</a>`,
	// 	balloonContentFooter: `111`,
	// }, 
	// {
	// 	iconLayout: 'default#image',
	// 	iconImageHref: '/template/images/placemark.svg',
	// 	iconImageSize: [150, 150],
	// 	iconImageOffset: [-70, -85]
	// });
	//myMap.geoObjects.add(place);


	var mapOb = document.getElementById('mapOb');
	//console.log(mapOb.dataset.cart);
	var x = Number(mapOb.dataset.cart.split(',')[0]);
	var y = Number(mapOb.dataset.cart.split(',')[1]);
	ymaps.ready(_init);
}
'use strict';

// popup
var popupLinks = document.querySelectorAll('.popup-link');
var body = document.querySelector('body');
var lockPadding = document.querySelectorAll('.lock-padding');

var unlock = true;

var timeout = 500;

if (popupLinks.length > 0) {
	var _loop = function _loop(index) {
		var popupLink = popupLinks[index];
		popupLink.addEventListener("click", function (e) {
			var popupName = popupLink.getAttribute('href').replace('#', '');
			var currentPopup = document.getElementById(popupName);
			popupOpen(currentPopup);
			e.preventDefault();
		});
	};

	for (var index = 0; index < popupLinks.length; index++) {
		_loop(index);
	}
}
var popupCloseIcon = document.querySelectorAll('.close-popup');
if (popupCloseIcon.length > 0) {
	var _loop2 = function _loop2(index) {
		var el = popupCloseIcon[index];
		el.addEventListener('click', function (e) {
			if (burger.classList.contains('_active')) {
				burger.classList.remove('_active');
				menu.classList.remove('_active');
				document.body.classList.remove('_lock');
			}
			popupClose(el.closest('.popup'));
			e.preventDefault();
		});
	};

	for (var index = 0; index < popupCloseIcon.length; index++) {
		_loop2(index);
	}
}

function popupOpen(currentPopup) {
	if (currentPopup && unlock) {
		var popupActive = document.querySelector('.popup._open');
		if (popupActive) {
			popupClose(popupActive, false);
		} else {
			bodyLock();
		}
		currentPopup.classList.add('_open');
		currentPopup.addEventListener('click', function (e) {
			if (!e.target.closest('.popup__content')) {
				popupClose(e.target.closest('.popup'));
			}
		});
	}
}
function popupClose(popupActive) {
	var doUnlock = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;

	if (unlock) {
		popupActive.classList.remove('_open');
		if (doUnlock) {
			bodyUnlock();
		}
	}
}

function bodyLock() {
	var lockPaddingValue = window.innerWidth - document.querySelector('.wrapper').offsetWidth + 'px';

	if (lockPadding.length > 0) {
		for (var index = 0; index < lockPadding.length; index++) {
			var _el = lockPadding[index];
			_el.style.paddingRight = lockPaddingValue;
		}
	}
	body.style.paddingRight = lockPaddingValue;
	body.classList.add('_lock');

	unlock = false;
	setTimeout(function () {
		unlock = true;
	}, timeout);
}

function bodyUnlock() {
	setTimeout(function () {
		if (lockPadding.length > 0) {
			for (var index = 0; index < lockPadding.length; index++) {
				var _el2 = lockPadding[index];
				_el2.style.paddingRight = '0px';
			}
		}
		body.style.paddingRight = '0px';
		body.classList.remove('_lock');
	}, timeout);

	unlock = false;
	setTimeout(function () {
		unlock = true;
	}, timeout);
}

document.addEventListener('keydown', function (e) {
	if (e.which === 27) {
		var popupActive = document.querySelector('.popup._open');
		popupClose(popupActive);
	}
});

(function () {
	if (!Element.prototype.closest) {
		Element.prototype.closest = function (css) {
			var node = this;
			while (node) {
				if (node.matches(css)) return node;else node = node.parentElement;
			}
			return null;
		};
	}
})();

(function () {
	if (!Element.prototype.matches) {
		Element.prototype.matches = Element.prototype.matchesSelector || Element.prototype.webkitMatchesSelector || Element.prototype.mozMatchesSelector || Element.prototype.msMatchesSelector;
	}
})();